import React, { Component } from "react";
import "../assets/scss/carousel.scss";

// Büyük boyutlu görsel
import team1Desktop from "../assets/img/main/flatart-slider-news.gif";

class Slider extends Component {
  constructor(props) {
    super();

    this.state = {
      currentIndex: 0
    };
  }

  render() {
    const { currentIndex } = this.state;

    return (
      <div className="slider">
        <div className="slider-image-container">
          <img
            className={`slider-image desktop ${currentIndex === 0 ? "active" : ""}`}
            src={team1Desktop}
            alt={`Image ${currentIndex + 1}`}
            width={1920}
            height={600}
            loading="lazy"
          />
        </div>
      </div>
    );
  }
}

export default Slider;
